import payload_plugin_0q440vOpuz from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_VP4ZzaBL3k from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_emTINs15Av from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dyi65N5RG1 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_p5gxJpVRpC from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PQQ0Zpm1XT from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_U9uNB2UdWR from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_S5lExfwiZb from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_31LLh8gruO from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_eie5c4Xi3V from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._bc5hl4yumpdvpomf6riqb5zilm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Ym2boIUqUF from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
export default [
  payload_plugin_0q440vOpuz,
  revive_payload_client_VP4ZzaBL3k,
  unhead_emTINs15Av,
  router_dyi65N5RG1,
  payload_client_p5gxJpVRpC,
  navigation_repaint_client_PQQ0Zpm1XT,
  check_outdated_build_client_U9uNB2UdWR,
  chunk_reload_client_S5lExfwiZb,
  plugin_vue3_31LLh8gruO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eie5c4Xi3V,
  plugin_client_Ym2boIUqUF
]