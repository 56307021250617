import { default as _91slug_93Ger8cBCyGyMeta } from "/app/pages/course/[slug].vue?macro=true";
import { default as courses1NpKJ59LD8Meta } from "/app/pages/courses.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index1cSJlhtVTcBMeta } from "/app/pages/index1.vue?macro=true";
import { default as login1JeNEGiVtOFMeta } from "/app/pages/login1.vue?macro=true";
export default [
  {
    name: "course-slug",
    path: "/course/:slug()",
    component: () => import("/app/pages/course/[slug].vue")
  },
  {
    name: "courses",
    path: "/courses",
    meta: courses1NpKJ59LD8Meta || {},
    component: () => import("/app/pages/courses.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index1",
    path: "/index1",
    component: () => import("/app/pages/index1.vue")
  },
  {
    name: "login1",
    path: "/login1",
    component: () => import("/app/pages/login1.vue")
  }
]